.Footer {
    display: none;
}

.FooterMobile {
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--wnwBlue);
    color: white;
    width: 100%;
    height: 45px;
    position: fixed;
    bottom: 0;
    text-align: center;
    z-index: 2;
}

/* this is for the wording under the icon */
.FooterMobile div div {
    display: block;
    font-size: 0.8rem;
}

.Footer a {
    color: var(--wnwOrange);
    text-decoration: none;
}

.FooterMobile a {
    color: var(--wnwOrange);
    text-decoration: none;
}

.currentTime {
    color: var(--wnwOrange);
}

@media (min-width: 1000px) {
    .Footer {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        color: white;
        width: 1000px;
        margin: 0 auto;
        padding: 0.5rem;
    }

    /* .Footer div {
        width: 270px;
    } */

    .FooterMobile {
        display: none;
    }
}