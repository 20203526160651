.Modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    overflow-y: auto;
    overflow-x: hidden;
}

.ModalDialog {
    position: relative;
    /* left: 10%;
    top: 20%; */
    background-color: white;
    width: 80%;
    margin: 5rem auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

.ModalDialog img {
    max-width: 100% !important;
    height: auto;
}

.ModalDialog iframe {
    max-width: 100% !important;
    height: auto;
}

@media (min-width: 1000px) {
    .ModalDialog {
        width: 700px;
        /* left: calc(50% - 300px); */
    }
}