.DrawerToggle {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    height: 100%;
}

.DrawerToggle div {
    color: #8F5C2C;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

@media (min-width: 768px) {
    /* this should be the same as NavItems */
    .DrawerToggle {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .DrawerToggle div {
        color: white;
        height: 100%;
        padding: 10px 15px;
        border-bottom: 4px solid transparent;
    }
    
    /* .DrawerToggle a.active, */
    .DrawerToggle div:hover,
    .DrawerToggle div:active {
        /* background-color: var(--wnwLightBlue); */
        border-bottom: 4px solid #40A4C8;
        color: white;
        cursor: pointer;
    }    
}