.NavItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    /* align-items: center; */
    height: 100%;
}

.NavItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    height: 100%;
}

.NavItem a {
    color: #8F5C2C;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavItem a:hover,
.NavItem a:active,
.NavItem a.active {
    color: #40A4C8;
}

@media (min-width: 768px) {
    .NavItems {
        flex-direction: row;
    }

    .NavItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavItem a {
        color: white;
        height: 100%;
        padding: 10px 15px;
        border-bottom: 4px solid transparent;
    }
    
    /* .NavItem a.active, */
    .NavItem a:hover,
    .NavItem a:active {
        /* background-color: var(--wnwLightBlue); */
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}